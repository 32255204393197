import * as React from "react"
import '@fontsource/roboto/400.css';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Link } from "gatsby"
import ContactSupportIcon from '@mui/icons-material/ContactSupport';


import "./layout.css"

const drawerWidth = 240;

const Layout = ({ location, children }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div style={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100%"
    }}>
      <Typography sx={{ my: 2, display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "2rem" }} className="logo">
        <span>The Food API</span>
        <img src='/icons/icon-large.png' width={56} height={24} alt="The Food Api logo" style={{ marginLeft: "1rem" }} />
      </Typography>
      <List>
        {[['Account', <AccountCircleIcon />, "/account"], ["Keys", <KeyIcon />, "/account/keys"], ["Snapshots", <FileDownloadIcon />, "/account/snapshots"]].map((content, index) => (
          <ListItem key={content[0]} disablePadding>
            <Link to={content[2]} style={{ width: "100%", color: "inherit", textDecoration: "none" }}>
              <ListItemButton>
                <ListItemIcon>
                  {content[1]}
                </ListItemIcon>
                <ListItemText primary={content[0]} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <List style={{ marginTop: "auto" }}>
        <ListItem key={"logout"} disablePadding>
          <Link to={'/support'} style={{ width: "100%", color: "inherit", textDecoration: "none" }}>
            <ListItemButton>
              <ListItemIcon>
                <ContactSupportIcon />
              </ListItemIcon>
              <ListItemText primary={"Support"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key={"logout"} disablePadding>
          <ListItemButton onClick={() => fetch('https://api.thefoodapi.com/logout', {
            method: "GET",
            credentials: "include"
          }).then(resp => {
            if (resp.status === 200) {
              window.location = '/login'
            }
          })}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </ListItem>
      </List>
    </div >
  );

  const container = typeof window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <Box style={{ minHeight: "100vh", display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{
            display: { sm: 'none' },
            background: "#007788"
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography variant="h6" noWrap component="div">
              Responsive drawer
            </Typography> */}
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ display: "flex", flexDirection: "column", flexGrow: 1, p: 3, paddingTop: { xs: "4rem" }, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          {children}
          <div style={{
            marginTop: "auto",
            bottom: "0",
            width: "100%"
          }}>
            <div style={{
              textAlign: "center",
              marginBottom: "1rem"
            }}>
              <a
                style={{
                  color: "#00778875"
                }}
                href="https://www.privacypolicygenerator.info/live.php?token=fziOD65GhSRDzONVqJcFxdCxZYBSMwDc" target="_blank" alt="Privace policy">Privacy policy</a>
            </div>
          </div>
        </Box>
      </Box>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
