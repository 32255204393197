import * as React from "react"
import Layout from "../../components/layoutAccount"
import '../../components/index.css'
import { TextField } from "@mui/material";
import { useState } from "react";
import { Link } from "gatsby"

const pageStyles = {
    color: "#232129",
    padding: "24px 12px",
    fontFamily: "Roboto, sans-serif, serif",
    maxWidth: "95%",
}

const CredentialsModal = ({ callback }) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")

    function login() {
        fetch('https://api.thefoodapi.com/login', {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({ email, password })
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(data => callback(data))
            } else {
                setError("Login failed")
            }
        })
    }

    return <div style={{
        position: "fixed",
        inset: "0",
        background: "rgba(0,0,0,0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "1101",
        backdropFilter: "blur(1px)"
    }}>
        <div style={{
            width: "20rem",
            maxWidth: "95%",
            background: "white",
            borderRadius: "8px",
            padding: "2rem 1rem"
        }}>
            <div style={{
                background: 'url(/icons/icon-large.png)',
                backgroundSize: "cover",
                width: "84px",
                height: "36px",
                margin: "auto"
            }}></div>
            <h2 style={{ textAlign: "center" }}>Renew your credentials</h2>
            <TextField size="small" InputLabelProps={{ shrink: true }} fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} sx={{ marginTop: "1rem" }} />
            <TextField size="small" InputLabelProps={{ shrink: true }} type="password" fullWidth label="Password" onChange={(e) => setPassword(e.target.value)} sx={{ marginTop: "1rem" }} />
            <div
                onClick={login}
                style={{
                    padding: "0.5rem",
                    background: "#007788",
                    textAlign: "center",
                    borderRadius: "4px",
                    color: "white",
                    marginTop: "1rem",
                    cursor: "pointer"
                }}><b>Login</b></div>
            {error && <div style={{ color: "red" }}>{error}</div>}
        </div>
    </div>
}

const IndexPage = ({ location }) => {
    const [accountInfo, setAccountInfo] = React.useState(null)
    const [askCredentials, setAskCredentials] = React.useState(false)
    const [hasPlan, setHasPlan] = React.useState(false)

    React.useEffect(() => {
        fetch('https://api.thefoodapi.com/account/info', {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include"
        }).then(resp => {
            if (resp.status === 403) {
                setAskCredentials(true)
            } else if (resp.status === 200) {
                resp.json().then(data => setAccountInfo(data))
            }
        })

        fetch('https://api.thefoodapi.com/account/plan', {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include"
        }).then(resp => {
            if (resp.status === 403) {
                setHasPlan(false)
            } else if (resp.status === 200) {
                setHasPlan(true)
            }
        })
    }, [])

    return (
        <Layout location={location}>
            {askCredentials && <CredentialsModal callback={(accountInfo) => {
                setAskCredentials(false)
                setAccountInfo(accountInfo)
            }} />}
            <div style={pageStyles}>
                <h2>Personal information</h2>
                <div>
                    <TextField label="Email" value={accountInfo?.email} InputLabelProps={{ shrink: true }} disabled={true} fullWidth sx={{ display: "flex", maxWidth: "30rem", marginTop: "1rem", marginBottom: "2rem" }} />
                    <TextField label="Password" value={"*************"} disabled={true} fullWidth sx={{ maxWidth: "30rem", display: "flex", }} />
                    <div>
                        <Link to='/password-reset' style={{ color: "rgba(0, 119, 136, 0.46)" }}>Change password</Link>
                    </div>
                </div>
                <br />
                <br />
                <h2>Payment information</h2>
                <div>
                    <div>Your payment information is stored and managed by our payment provider Stripe.</div>
                    <div style={{ display: "flex" }}>
                        {hasPlan ?
                            <a href="https://billing.stripe.com/p/login/dR63fJ4Ioc488uY4gg" target="_blank" rel="nofollow" className="clickable" style={{ textDecoration: "none", padding: "1rem", borderRadius: "4px", marginTop: "1rem", background: "#007788", color: "white", fontWeight: "bold" }}>Manage my payment information</a> :
                            <div className="clickable" style={{ padding: "1rem", borderRadius: "4px", marginTop: "1rem", background: "#00778845", color: "white", fontWeight: "bold" }}>Manage my payment information</div>
                        }
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div className="clickable" style={{ padding: "1rem", borderRadius: "4px", marginTop: "6rem", background: "#B04773", color: "white", fontWeight: "bold" }}>Delete account</div>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage

export const Head = () => <title>Pricing Page</title>
